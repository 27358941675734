.form-input {
  &__adornments {
    color: #999;
    font-size: 14px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__form-control {
    min-width: "160px";
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }
}
