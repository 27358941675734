.loading-div {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-loading-div {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
