.email-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    margin: 50px 50px 40px 50px;
  }

  &__info {
    font-size: 16px;
    line-height: 16px;
    /* or 100% */
    text-align: center;
    white-space: pre-line;
    word-break: normal;
    color: #797979;
    margin: 0px 20px 50px 20px;
  }

  &__button {
    margin: 0px 0px 30px 0px;
  }
}
