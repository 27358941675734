@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 129, 250, 0.5);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(0, 129, 250, 0);
  }
}

.edit-pivot-form {
  &__tabBar {
    display: flex;
    position: fixed;
    z-index: 99996 !important;
    right: 0px;
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #ddd;
    box-shadow: 0px 0px 10px 0px gray;
    background-color: white;
    justify-content: center;
    align-items: center;
  }

  &__map {
    width: 100%;
    height: 520px;
    display: block;
  }

  &__3d-button {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    margin-bottom: -7px;
    margin-right: 15px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid gray;
    border-radius: 50%;

    img {
      margin: 0px;
    }
  }

  &__last-settings {
    font-size: 0.8em;
    opacity: 0.5;
  }

  &__done-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation: shadow-pulse-big 1s 1;
  }
}
