@import "../../../../../../../../style/Mixins";

.form-input-position {
  &__item {
    margin: 20px 0 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    @include mobile-landscape-and-below {
      flex-direction: row;
    }

    img {
      margin-right: 0px;
    }

    &__label {
      top: -15px;
      left: 0;
      position: absolute;
      color: #999;
      font-size: 0.9rem;
      font-weight: 500;
    }

    &__input-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;

      @include mobile-landscape-and-below {
        grid-template-columns: 1fr;
      }
    }
  }
}
