@import "../../../../style/Mixins.scss";

$large: 1365px;

@mixin selected-irpd($padding) {
  padding: $padding;
  position: relative;
  z-index: 0;

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 15px;
    @media (max-width: 1556px) {
      grid-template-columns: 5fr 5fr;
    }
    @media (max-width: $large) {
      grid-template-columns: 100%;
      grid-gap: 20px;
    }
  }

  &__column {
    position: relative;
    max-width: 100%;
    min-width: 400px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    row-gap: 15px;

    @include tablet-landscape-and-below {
      min-width: auto;
    }
  }

  &__column_flex {
    position: relative;
    max-width: 100%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @include tablet-landscape-and-below {
      min-width: auto;
    }
  }

  &__column_single_item {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    row-gap: 15px;
    height: calc(100vh - 150px);
    grid-template-rows: min-content;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
}

.selected-irpd {
  @include selected-irpd(10px 10px 10px 55px);
}

.selected-pivot-mobile {
  @include selected-irpd(0px 0px 30px 0px);
}
