@import "../../../../../../style/Mixins";

.edit-farm-form {
  &__map {
    width: 100%;
    height: 400px;
    display: block;
  }

  &__button-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet-landscape-and-below {
      padding-top: 12px;
      padding-bottom: 40px;
    }
  }

  &__users {
    padding-top: 12px;

    h2 {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #444;
      font-weight: 600;
    }

    li {
      color: #777;
      font-weight: 400;
      font-size: 0.9rem;
    }
  }

  &__centralize-button {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
}
