.dialog-modal {
  width: 100%;
  height: 100%;
  padding: 20px;

  h2 {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #111;
    margin: 0px;
  }

  &__content {
    height: 90%;
    text-align: center;
    padding: 13px;
  }

  &__button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 5px;
    }
  }
}
