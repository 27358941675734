.button {
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: 600;
  color: #333;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  background-color: transparent;

  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  outline: none;

  svg {
    height: 18px;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  }
}
