@import "../../../../../../style/Mixins.scss";

@mixin table-pivot($place) {
  width: 100%;
  max-height: 120px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  border-bottom: 1px solid silver;
  font-size: 1rem;

  @if $place {
    border-bottom: none;
  }

  @include tablet-landscape-and-below {
    font-size: 0.8rem;
  }

  &__index {
    background-color: silver;
    height: 60px;
    width: 60px;
    margin: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 1.1rem;

    @include tablet-landscape-and-below {
      height: 40px;
      width: 40px;
      font-size: 1rem;
      margin: 3px 0px 3px 3px;
    }
  }

  &__info {
    width: 80%;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__box {
      display: flex;
      flex-direction: column;
      text-align: justify;

      &__direction {
        display: flex;
        flex-direction: row;
      }

      &__angle {
        font-size: 1rem;
      }

      &__wet {
        font-size: 0.7rem;
        border-radius: 8px;
        background-color: #4679ed;
        color: white;
        padding: 4px 8px;
        margin-left: 5px;
      }

      &__dry {
        font-size: 0.7rem;
        border-radius: 8px;
        background-color: #934200;
        color: white;
        padding: 4px 8px;
        margin-left: 5px;
      }
    }
  }
}

.table-pivot-segmentsLast {
  @include table-pivot(true);
}

.table-pivot-segments {
  @include table-pivot(false);
}
