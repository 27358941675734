@mixin layout($padding) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: $padding;
  overflow-y: scroll;
}

.edit-bauer-pivot {
  @include layout(10px 40px 5px 55px);
}

.edit-bauer-pivot-mobile {
  @include layout(0px 0px 55px 0px);
  overflow-y: hidden;
}
