@import "../../../../style/Vars.scss";
@import "../../../../style/Mixins.scss";

$radius: 7px;

.dashboard-box {
  width: 100%;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.23);
  border-radius: $radius $radius 0 0;
  background: white;
  display: inline-block;
  height: fit-content;

  &__header {
    min-height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    background-color: white;
    border-bottom: 1px solid #ddd;
    border-radius: $radius $radius 0px 0px;
    overflow: hidden;
    padding: 9px;
    height: min-content;

    &__grid-1-3-1 {
      // height: 75px;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      align-items: center;

      background-color: white;
      border-bottom: 1px solid #ddd;
      border-radius: $radius $radius 0px 0px;
      overflow: hidden;
      padding: 5px;
    }

    &__single {
      // height: 75px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;

      background-color: white;
      // border-bottom: 1px solid #ddd;
      border-radius: $radius $radius 0px 0px;
      overflow: hidden;
      padding: 9px;
    }

    &__grid-3-9 {
      display: grid;
      grid-template-columns: 3fr 9fr;
      align-items: center;

      background-color: white;
      border-bottom: 1px solid #ddd;
      border-radius: $radius $radius 0px 0px;
      overflow: hidden;
      padding: 9px;
    }

    &__grid-9-3 {
      display: grid;
      grid-template-columns: 10fr 2fr;
      align-items: center;

      background-color: white;
      border-bottom: 1px solid #ddd;
      border-radius: $radius $radius 0px 0px;
      overflow: hidden;
      padding: 9px;
    }
    h2 {
      margin: 0px;
      font-size: 1rem;
      font-weight: 500;
      color: #555;
    }

    &__left {
      text-align: left;
      grid-column: 1;

      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__center {
      text-align: center;
      text-overflow: ellipsis;
      h2 {
        margin: 0px;
        font-size: 1rem;
        font-weight: 500;
        color: #555;
      }
    }

    &__right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__two-thirds {
      grid-column: 2;
      grid-row: 1;
      align-self: center;
      text-align: left;
    }
  }

  &__content {
    // height: 100%;
    background-color: white;
    // border: 1px solid #ddd;
    // overflow: auto;
    // max-height: calc(100vh - 150px);

    @include tablet-landscape-and-below {
      max-height: fit-content;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }

  &__content-no-scroll {
    box-sizing: border-box;
    background-color: white;
    // border: 1px solid #ddd;

    // Esse cara abaixo talvez conserta o scroll no editpivot
    // height: 85vh;

    @include tablet-landscape-and-below {
      margin-bottom: 10px;
    }
  }
}
