.info-box {
  position: relative;
  width: 390px;
  height: 390px;

  @media (max-width: 1556px) {
    width: 310px;
    height: 310px;
  }

  @media (max-width: 1024px) {
    width: 340px;
    max-width: 340px;
    height: 340px;
  }

  padding: 10px;

  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.015);

  margin-bottom: 10px;

  &__grid {
    position: relative;
    height: 10%;
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  &__left-element {
    width: 100%;
    height: 100%;
  }

  &__center-element {
    text-align: center;
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    line-height: 1rem;
    width: 100%;
    height: 100%;
  }

  &__right-element {
    width: 24px;
    height: 28px;
    align-self: center;
    justify-self: right;
    position: absolute;
    bottom: 5px;

    @media (max-width: 1024px) {
      bottom: 7px;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }
}
