@import "./components/Button.scss";
@import "./components/GeneralForm.scss";

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0px;
  font-size: 18px;
  background-color: #dddddd;
  height: 100%;
}

// ::-webkit-scrollbar {
//   width: 0px;
// }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
