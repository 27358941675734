@import "../../../../style/Vars.scss";

.password-recovery {
  width: 100%;
  background: $gradient-blue-green;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    @extend %card-attr;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__cardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    max-height: 52px;
    padding: 30px 0px 30px 0px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    &__title {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__card-information {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;

    &__firstSelect {
      margin: 20px 30px 20px 30px;
    }
    &__select {
      margin: 0px 30px 40px 30px;
    }
  }

  &__button {
    margin: 20px 0px 20px 0px;
  }
}
