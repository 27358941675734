.dashboard-box-title {
  width: 100%;
  text-overflow: ellipsis;

  h2 {
    margin: 0px;
    font-size: 1rem;
    font-weight: 500;
    color: #555;
  }
}
