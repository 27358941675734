@import "../../../../../../../style/Mixins.scss";

.SelectRadio {
  width: 600px;
  height: 80%;
  max-height: 80%;

  @include tablet-landscape-and-below {
    width: 100%;
    height: auto;
  }

  &__header {
    margin-bottom: 20px;

    @include tablet-landscape-and-below {
      padding: 10px;
    }
  }

  &__title {
    text-align: left;
    h3 {
      margin-bottom: 10px;
    }
  }

  &__list {
    max-height: 500px;
    overflow-y: auto;

    @include tablet-landscape-and-below {
      max-height: none;
    }
  }
}
