@import "../../../../style/Mixins.scss";

.language-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px 20px 10px;
  width: 100%;
  min-width: 250px;
  height: 100%;

  @include tablet-landscape-and-below {
    padding-top: 10px;
    padding-left: 20px;
    justify-content: start;
    width: 100%;
  }

  &__language-option {
    display: flex;
    font-size: 16px;
    border-radius: 5px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }

  &__language-option-selected {
    @extend .language-selector__language-option;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__language-option:hover {
    background-color: rgba(0, 0, 0, 0.07);
    transition: 0.5s;
  }
}
