.snackRoot {
  margin-top: 0px;

  &__notch-minimal {
    margin-top: 10px;
  }

  &__notch-full {
    margin-top: 40px;
  }
}
