@import "../../../style/Mixins.scss";

@mixin profile($padding) {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;

  padding: 10px 10px 55px 55px;

  &__content {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__grid-col-2-3 {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 35px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @include tablet-landscape-and-below {
      display: grid;
      grid-template-rows: 0.5fr 6fr;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }

  &__grid-col-2-2 {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 15px;
  }

  &__grid-row-1-1-1 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    width: 250px;

    @include tablet-landscape-and-below {
      width: 100%;
    }
  }

  &__section-name {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    @include tablet-landscape-and-below {
      margin-top: 13px;
      align-items: flex-start;
    }

    h3 {
      opacity: 0.7;
      font-weight: 500;
      font-size: 18px;
      @include tablet-landscape-and-below {
        margin-bottom: -10px;
      }
    }
  }
}

.container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile {
  @include profile(50px 55px 55px 105px);
}
