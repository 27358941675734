@import "../../style/Mixins.scss";

.geolocation-getter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
  padding: 13px;

  h4 {
    font-size: 1.1em;
    font-weight: normal;
    margin: 0px 0px 20px 0px;
  }

  &__container {
    height: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
  }

  &__grid-column-2-10 {
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: 70px 10fr;

    @include tablet-landscape-and-below {
      grid-template-columns: 70px 10fr;
    }
  }

  &__grid-row-1-1 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 3px;
  }

  &__label {
    font-weight: bold;
  }

  &__value {
    opacity: 0.65;
  }

  &__wait-for-precision {
    width: 100%;
    padding: 12px 0 18px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    height: 100%;
  }
}
