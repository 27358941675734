@import "../../../../../../../../style/Mixins.scss";

.hide-second-item {
  .MuiFormControl-root {
    visibility: hidden;
  }
}

.conventional-irrigation-v5 {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  width: 100%;

  @include tablet-landscape-and-below {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px;
    height: 100%;
    position: relative;
  }

  &__label {
    font-size: 15px;
    color: rgb(58, 58, 58);
  }

  &__row {
    display: flex;
    flex-direction: row;

    & > *:first-child > * {
      margin-right: 15px;
    }
  }

  &__row-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  &__center-row {
    width: 100%;
    text-align: center;

    margin-top: 20px;

    @include tablet-landscape-and-below {
      width: 100%;
    }
  }

  &__grid-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    align-items: center;
  }
}
