@import "../Vars.scss";
@import "../Mixins.scss";

$animation-time: 0.3s;

.drawerRight {
  max-height: 100vh;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 1s;

    &--inactive {
      display: none;
    }
  }

  &__bar {
    display: grid;
    grid-template-columns: 46px 6fr;
    position: fixed;
    top: 0px;
    right: 0;
    width: 960px;
    max-width: calc(100% - 46px);
    height: 100%;
    background-color: #2f4050;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    z-index: 1000;
    transition: width $animation-time;
    // user-select: none;
    overflow: hidden;
    overflow-y: auto;
    // margin-right: 15px;

    @include tablet-landscape-and-below {
      margin-right: 0;
      padding-left: 46px;
      width: 266px;
      top: 55px;
      left: -46px;
    }

    // & > * {
    //   user-select: none;
    // }

    &--inactive {
      display: grid;
      grid-template-columns: 46px;
      width: 46px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background-color: #2f4050;
    }

    &__x {
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
    }

    &__right {
      background-color: #ffffff;
    }
  }

  &__items {
    color: #9ea6b9;
    color: hsla(0, 0%, 100%, 0.4);
  }

  &__submenu {
    background-color: #293745;
    overflow: hidden;
    max-height: 0px;
    transition: max-height $animation-time;

    &--visible {
      max-height: 800px;
    }

    &__item {
      cursor: pointer;
      height: 36px;
      padding-left: 8px;

      overflow: hidden;

      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 0.8rem;
      white-space: nowrap;

      transition: color $animation-time;

      &:hover {
        color: white;
      }

      svg {
        height: 7px;
      }
    }

    &__label {
      transition: margin-left $animation-time;
      &--hidden {
        margin-left: 20px;
      }
      &--selected {
        color: white;
        font-weight: bold;
      }
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-size: 1.3rem;
    padding: 12px 8px;
    position: relative;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.08);
    font-weight: bold;

    transition: color $animation-time, background-color $animation-time;

    &:hover {
      color: white;

      svg {
        color: white;
      }
    }

    &--selected {
      border-left: 4px solid $color-green;
      background-color: #293745;
      padding-left: 4px;

      & > * {
        color: white;
      }
    }

    &--submenu-active {
      background-color: #293745;
      border-bottom: none;
    }

    svg {
      height: 20px;
      object-fit: contain;
    }

    &__label {
      margin-left: 8px;
      font-size: 0.8rem;
      white-space: nowrap;
      transition: margin-left $animation-time;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &--hidden {
        margin-left: 14px;
      }
    }

    &__arrow {
      position: absolute;
      right: 15px;

      &--closed {
        display: none;
      }
    }
  }
}
