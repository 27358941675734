@import "../../../style/Vars.scss";
@import "../../../style//Mixins.scss";

.signUp-page {
  width: 100%;
  background: $gradient-blue-green;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    margin: 32px 0px;
    width: 250px;
    object-fit: contain;
  }

  &__name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    // margin-top: 10px;
  }

  &__ddiNumber {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
    // margin-top: 10px;
  }

  &__logo {
    margin: 32px 0px;
    width: 250px;
    object-fit: contain;
  }

  &__orLine {
    display: inline-block;
    position: relative;
    margin: 0px 0px 10px 0px;
    color: #aaa;
    font-size: 0.8rem;

    &:before {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #aaa;
      top: 25%;
      width: 200px;
    }
    &:after {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #aaa;
      top: 25%;
      width: 200px;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }

  &__card {
    @extend %card-attr;
    // padding: 24px;
    //margin-bottom: 200px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    position: relative;

    @include tablet-landscape-and-below {
      margin-top: -10px;
      width: 95%;
    }
  }

  &__reCaptcha {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0px 20px 0px;

    border-bottom: 1px solid silver;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
  }

  &__title {
    width: 100%;
    // font-size: 0.7rem;
    color: #555;
    text-align: center;
    display: grid;
    border-bottom: 1px solid silver;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 20px 10px;
  }

  &__footer {
    width: 100%;
    font-size: 0.7rem;
    color: #555;
    display: flex;
    border-bottom: 1px solid silver;
    row-gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
  }

  &__link {
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    color: #aaa;
    font-size: 0.8rem;
    margin: 22px 0px 22px 0px;
    border-bottom: 1px solid silver;
    background-color: transparent;
    border: none;
    padding: 0px;

    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__buttonSignUp {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 22px 0px 22px 0px;
  }

  &__newClient {
    text-decoration: none;
    color: #aaa;
    font-size: 0.8rem;
    margin-right: 5px;
  }
  &__signUp {
    text-decoration: none;
    color: #0089d6;
    font-size: 0.8rem;
    border-bottom: 1px solid #0089d6;
  }

  &__buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin: 22px 0px 22px 0px;
  }

  &__buttonsSignUp {
    display: flex;
    width: 100%;
    height: 180px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px;
  }
}
