@import "../../../../../../style/Mixins.scss";

.edit-pivot-form-v5 {
  &__tabBar {
    display: flex;
    position: fixed;
    z-index: 99996 !important;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    border-top: 1px solid #ddd;
    box-shadow: 0px 0px 10px 0px gray;
    background-color: white;
    justify-content: center;
    align-items: flex-start;
  }

  &__table {
    display: flex;
    flex-direction: column;

    .MuiTable-root {
      background: rgba(235, 235, 235, 0.6);
    }

    @include tablet-landscape-and-below {
      margin: 0;
    }
  }

  &__segment-button {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MuiButton-endIcon {
      margin: 0px;
    }
  }

  &__segment-pivot-button {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__segment-button-remove {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet-landscape-and-below {
      width: 100%;
    }
    .MuiButton-endIcon {
      margin: 0px;
    }
  }

  &__topic {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    // color: #444;
  }

  &__information {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  &__container {
    display: grid;
    grid-template-columns: minmax(300px, 600px) 1fr;
    grid-gap: 10px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
    }
  }

  &__container_max {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
    }
  }

  &__container-endGun {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 10fr;
    grid-gap: 20px;
  }
}
