@import "../../../../../style/Mixins.scss";

.device-box-devices {
  &__content {
    padding: 20px;
  }

  &__container {
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    @include tablet-landscape-and-below {
      max-height: none;
      overflow-y: auto;
    }
  }

  &__label {
    text-align: center;
    padding: 5px 14.4px;
    font-size: 0.85em;
    opacity: 0.5;
    border-bottom: 1px solid rgb(206, 206, 206);
  }
}

.pivot-item_device-div {
  display: flex;
  flex-direction: column;

  & > .__uncollapsable {
    position: relative;

    & > .__overlay-items {
      position: absolute;
      top: 20%;
      right: 10px;

      opacity: 0.2;

      transform-origin: 50% 50% 50%;
    }

    & > .icons {
      display: flex;
      flex-direction: row;
      position: absolute;

      bottom: 10px;
      right: 10px;
    }
  }

  &__analyze_text {
    font-size: 12px;
    margin-left: 10px;
    padding-top: 5px;
  }

  &__bottom-div {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }

  &__pivot_info_div {
    display: flex;
    flex-direction: row;
    background-color: #d6d6d6;

    &__sub {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      padding: 10px;
    }

    &__info {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.devices {
  &__loading-div {
    width: 100%;
    min-width: 300px;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.filter {
  &__container {
    text-align: center;
    font-size: 0.8em;
  }

  &__item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
