.table-root {
  width: 100%;
  height: 100%;
  border-width: 0px;
  border: 0px !important;
  background: white;
  border-style: none;
  box-shadow: none;
}

.table-container {
  max-height: 500px;
  border: 0px;
}

.custom-name-heading {
  display: flex;
  align-items: center;
}

.custom-index {
  max-width: 45px;
  max-height: 45px;
  margin: 0px 20px;
  color: white;
}
