@import "../../../../../../style//Mixins.scss";

.basic-info {
  width: 100%;
  max-width: 100%;
  height: 100%;

  &__line-overlay {
    padding-top: 3px;
    position: absolute;
    bottom: 12px;
    left: 0;
    height: 15px;
    // border: 1px solid red;
    background-color: #fbfbfb;
    width: 100%;
    z-index: 999999;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      font-size: 0.8em;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 10px;
    align-items: center;
    margin-bottom: 25px;
    padding: 10px;
  }

  &__graph {
    position: relative;
    width: 280px;
    height: 280px;

    margin-bottom: 10px;
  }

  &__bottom-info {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    position: absolute;
    bottom: 15px;
    padding-top: 4%;
  }

  &__bottom-sub-info {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    position: absolute;
    bottom: 0px;
    padding-top: 3%;
  }

  &__tables-flex-container {
    width: 100%;
    display: flex;
    flex-direction: row;

    @include tablet-landscape-and-below {
      flex-direction: column;
    }
  }

  &__two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding: 15px;

    @include tablet-landscape-and-below {
      padding: 8px;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 4fr 4fr;
    padding: 15px;
    grid-gap: 30px;

    @include tablet-landscape-and-below {
      padding: 8px;
    }
  }

  &__center-text {
    flex: 1;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 15px;

    @include tablet-landscape-and-below {
      padding: 8px;
    }
  }

  &__row-center {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    align-items: center;
  }

  .MuiTable-root {
    background: rgba(0, 0, 0, 0.015);
  }

  &__consumption-table {
    // style={{ marginTop: "10px", width: "80vw", overflow: 'auto' }}
    margin-top: 10px;
    width: 100%;

    @include tablet-landscape-and-below {
      padding-left: 2vw;
      width: 80vw;
      overflow: auto;
    }
  }

  &__header-infos {
    padding: 10px 0px 15px 5px;
    font-size: 0.8em;
    opacity: 0.8;
    text-align: center;
  }
}

.margin-top-10 {
  margin-top: 10px;

  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  font-size: 0.8em;
  text-align: center;
}

.popover-values {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    font-weight: 500;
  }
  &__margins {
    margin: 5px 0px;
  }
  &__config-text {
    max-width: 250px;
    padding: 0px 10px;
    margin-top: 5px;
    text-align: center;
    font-size: 0.7em;
    color: gray;
  }
}

.loading-div {
  position: relative;
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.default-box-size {
  width: 280px;
  height: 280px;
}
