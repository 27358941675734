@mixin layout($padding) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: $padding;
}

.edit-farm {
  @include layout(10px 10px 10px 55px);
}

.edit-farm-mobile {
  @include layout(0px 0px 30px 0px);
}
