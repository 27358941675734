@import "../../../../style/Vars.scss";
@import "../../../../style//Mixins.scss";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: $color-green;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 2;

  h3 {
    color: white;
  }

  &__notch-full {
    height: 95px !important;
    align-items: flex-end !important;

    & > * {
      align-self: flex-end !important;
      justify-items: flex-end !important;
    }
  }

  &__notch-minimal {
    height: 65px !important;
    align-items: flex-end !important;

    & > * {
      align-self: flex-end !important;
      justify-items: flex-end !important;
    }
  }

  @include tablet-landscape-and-below {
    height: 55px;
    padding: 0px 0px;

    display: grid;
    grid-template-columns: 51px 1fr 51px;

    & > * {
      align-self: center;
      justify-items: center;
    }
  }

  // display: inline-grid;
  display: flex;
  justify-content: space-between;
  justify-items: start;
  flex-direction: row;
  align-items: center;
  // grid-template-columns: 2fr 4fr 2fr 1fr;
  padding: 0px 24px;
  z-index: 2;

  img {
    height: 28px;
    object-fit: contain;
  }

  .MuiIcon-colorPrimary {
    color: white;
    background-color: white;
  }

  &__mobile-farm-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    & > h3 {
      // margin-block-end: 0.6em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      margin: 0px;
    }

    & > .notch {
      margin-bottom: 12px;
    }
  }
  &__first-steps {
    display: flex;
    flex-direction: row;
    margin: 0px 51px 0px 0px;
  }
}

.select_button {
  display: flex;
  justify-content: space-around;
}

.native-select {
  padding: 0px 0px 0px 0px !important;
}
