@import "../../../../../../style/Mixins.scss";

.generalbox {
  display: flex;
  flex-direction: column;

  @include tablet-landscape-and-below {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }

  &__inner {
    display: grid;
    grid-template-columns: 4fr 5fr 3fr;
    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
    }
  }

  &__lowPermissionInner {
    display: grid;
    grid-template-columns: 4fr 5fr 3fr;
    @include tablet-landscape-and-below {
      max-height: 420px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px;
    border-top: 1px solid #ddd;

    @include tablet-landscape-and-below {
      padding: 0;
      grid-column: 1 / 3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 8px;
      column-gap: 8px;

      @media (max-width: 350px) {
        display: flex;
        flex-direction: column;
      }
    }

    button {
      margin-top: 15px;
      @include tablet-landscape-and-below {
        margin-top: 0;
      }
    }

    &__offlinealert {
      @include tablet-landscape-and-below {
        grid-column: 1 / 3;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }

  &__status {
    span {
      font-weight: 600;
      font-size: 0.7rem;
      padding: 4px 8px;
      border-radius: 8px;
      color: white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;

    @include tablet-landscape-and-below {
      padding: 0;
    }

    h2 {
      margin: 8px 0px;
      font-weight: 500;
      font-size: 1.8rem;
    }

    &__items {
      display: flex;
      flex-direction: column;
      align-items: left;
      column-gap: 5px;
      row-gap: 15px;
      margin-top: 15px;

      @include tablet-landscape-and-below {
        display: grid;
        grid-template-columns: 7fr 3fr;
        margin: 5px 5px 10px 5px;
        row-gap: 12px;
      }
    }

    &__item {
      font-size: 0.8rem;
      margin-right: 12px;

      display: flex;
      flex-direction: row;
      align-items: center;
      color: #444;
      margin-top: 4px;

      svg {
        width: 20px;
        margin-right: 8px;
      }
    }
  }

  &__last-update {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 18px;
    color: #ccc;
    font-size: 0.8rem;
    text-align: right;

    @include tablet-landscape-and-below {
      position: relative;
      grid-column-end: 2;
      bottom: auto;
      right: auto;
      text-align: left;
    }
  }
}

.pivot-irrigation-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 30px;
  height: 100%;

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    & > button {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    @include tablet-landscape-and-below {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 15px;
    }
  }

  &__label {
    font-weight: 500;
    color: #222;
  }
}
