@import "../../../../../../../../style/Mixins.scss";

.conventional-irrigation {
  max-width: 100%;
  width: 100%;
  padding-bottom: 15px;

  @include tablet-landscape-and-below {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px;
    height: 100%;
    position: relative;
  }

  &__label {
    font-size: 15px;
    color: rgb(58, 58, 58);
  }

  &__label-op-mode {
    float: left;
    padding-top: 15px;
    font-size: 13.5px;
    margin-right: 14px;
    color: #999;
  }

  &__row {
    display: flex;
    flex-direction: row;

    & > *:first-child > * {
      margin-right: 15px;
    }
  }

  &__grid-1-3-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    align-items: center;
  }

  &__grid-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    align-items: center;
  }

  &__center-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 20px;

    @include tablet-landscape-and-below {
      width: 100%;
    }
  }

  &__center-row-without-margin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

    @include tablet-landscape-and-below {
      width: 100%;
    }
  }

  &__grid-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    align-items: center;
    @include tablet-landscape-and-below {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
