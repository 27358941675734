@import "../../../../../../../../style/components/Placholder.scss";
@import "../../../../../../../../style/Mixins.scss";

.edit-farm-form-placeholder {
  position: relative;
  cursor: pointer;
  user-select: none;
  // border-bottom: 1px solid #eee;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__map {
    margin-top: 10px;
    width: 100%;
    height: 400px;

    @extend %animation-background;
  }

  &__row-10-2 {
    display: grid;
    grid-template-columns: 10fr 2fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__4 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 45%;
    height: 50px;
    @extend %animation-background;
  }

  &__6 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 65%;
    height: 50px;
    @extend %animation-background;
  }

  &__12 {
    margin-top: -25px;
    margin-bottom: 30px;
    width: 100%;
    height: 80px;
    @extend %animation-background;
  }
}
