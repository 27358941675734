@import "../../../style/Vars.scss";

.login-page {
  width: 100%;
  background: $gradient-blue-green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;

  @media only screen and (max-width: 1024px) {
    overflow-x: hidden;
  }

  &__logo {
    margin: 32px 0px;
    width: 300px;
    object-fit: contain;
  }

  &__orLine {
    display: inline-block;
    position: relative;
    margin: 10px 0px 10px 0px;
    color: #aaa;

    &:before {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #aaa;
      top: 25%;
      width: 125px;
    }
    &:after {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #aaa;
      top: 25%;
      width: 125px;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }

  &__card {
    @extend %card-attr;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
  }

  &__title-container {
    position: relative;
    width: 100%;
    border-bottom: 1px solid silver;
  }

  &__title {
    width: 100%;
    font-size: 0.7rem;
    color: #555;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    padding: 20px 0px 20px 0px;
  }

  &__language-overlay {
    position: absolute;
    width: 100%;
    left: calc(100% - 61px);
    top: 15px;
  }

  &__link {
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    color: #aaa;
    font-size: 0.8rem;
    margin: 22px 0 0;
    border-bottom: 1px solid silver;
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none;

    &:hover {
      text-decoration: underline;
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
    }
  }

  &__privacy-container {
    width: 90%;
    padding: 20px 0;
    font-size: 0.6rem;
    color: #aaa;
    text-align: center;
  }

  &__privacy-link {
    width: 100%;
    padding: 0px;
    color: $color-green !important;
    text-decoration: none;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #aaa;
      outline: none;
      text-decoration: none;
    }
  }

  &__buttonSignUp {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 22px 0 22px 0;
  }

  &__newClient {
    text-decoration: none;
    color: #aaa;
    font-size: 0.8rem;
    margin-right: 5px;
  }
  &__signUp {
    cursor: pointer;
    text-decoration: none;
    color: #0089d6;
    font-size: 0.8rem;
    border-bottom: 1px solid #0089d6;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 22px;
    border-bottom: 1px solid silver;
  }
}
