@import "../../../../../../../../style/Mixins.scss";

.by-angle-irrigation_v5 {
  width: 100%;
  height: 100%;

  @include tablet-landscape-and-below {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 0px 13px 13px 13px;
    position: relative;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    column-gap: 20px;
  }

  &__table {
    height: 100%;
  }

  &__table-body {
    min-height: 35vh;
    max-height: 35vh;
    font-size: 0.7em !important;

    @include tablet-landscape-and-below {
      min-height: 27vh;
      max-height: 27vh;

      // padding: 0px 13px 13px 13px;
    }

    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    column-gap: 10px;
    background-color: white;
    font-size: 15px;
    color: black;
    padding: 10px;
    text-align: center;
  }

  &__table-head {
    font-size: 0.8em !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    background-color: gray;
    font-size: 22px;
    color: white;
    padding: 10px;
    align-items: center;
    text-align: center;
    @include tablet-landscape-and-below {
      // padding: 0px 13px 13px 13px;
      margin: 0px 5px 0px 5px;
    }
    // padding: 0px 13px 13px 13px;
  }

  &__3-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    padding: 5px;
  }

  &__grid-2-1 {
    width: 600px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;
    padding: 5px;
  }

  &__2-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    @media only screen and (min-width: 800px) {
      padding: 5px;
    }

    @media only screen and (max-width: 800px) {
      padding: 10px 35px;
    }
  }

  &__2-auto-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    padding: 5px;
  }

  &__internal-2-columns {
    width: 290px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }

  &__1-column {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0% 5% 2% 5%;
  }

  &__1-column-big-screen {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 5px;
  }

  &__form {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  &__toggle-container {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
  }

  &__center-row-without-margin {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include tablet-landscape-and-below {
      width: 100%;
    }
  }

  &__image-input {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
  }

  &__inputs {
    height: 100%;
    @include tablet-landscape-and-below {
      padding: 0px 13px 13px 13px;
    }
  }

  &__canvas-container {
    z-index: 999;
    width: 100%;
    height: 400px;
    overflow: hidden;
  }

  &__map-container {
    display: flex;
    width: 100%;
    height: 400px;
  }

  &__fillets-circle {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    border-radius: 50px;
    background-color: #002372;
    color: #ffffff;
    margin: 5px;
  }

  &__fillets-circle-active {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    border-radius: 50px;
    border: solid 2px #ff8a00;
    background-color: #002372;
    color: #ffffff;
    margin: 5px;
  }

  &__fillets-disabled-circle {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    border-radius: 50px;
    border: solid 2px #d2d2d2;
    background-color: #d2d2d2;
    color: #ffffff;
    margin: 5px;
  }

  @media only screen and (min-width: 800px) {
    &__fillets {
      width: 100%;
      padding: 5px;
    }
  }
  @media only screen and (max-width: 800px) {
    &__fillets {
      width: 100%;
      padding: 0% 5% 5% 5%;
    }
  }

  &__fillets-container {
    display: flex;
    width: 100%;
    height: 50px;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-direction: row;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__fillets-container::-webkit-scrollbar {
    display: none;
  }

  &__fillets-trash {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 50px;
    overflow: hidden;
  }

  &__segment-button {
    text-align: center;

    .MuiButton-endIcon {
      margin: 0px;
    }
  }

  &__calculator-modal {
    position: fixed;
    z-index: 1000;
    top: 30%;
    @media only screen and (min-width: 800px) {
      left: 40%;
    }
    @media only screen and (max-width: 800px) {
      left: 20%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 800px) {
      width: 20%;
    }
    @media only screen and (max-width: 800px) {
      width: 60%;
    }
    height: 250px;

    background-color: white;
    padding: 20px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  &__button-mobile {
    line-height: 1.2 !important;
  }
}
