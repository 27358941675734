@import "../../../../../../../../style/Mixins.scss";

.hide-second-item {
  .MuiFormControl-root {
    visibility: hidden;
  }
}

.segment-modal {
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 700px;

  @include tablet-landscape-and-below {
    max-width: 100%;
  }

  h4 {
    font-size: 0.9em;
    font-weight: normal;
    margin: 10px 10px 0px 10px;
  }

  &__row-6-6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 40px;

    @include tablet-landscape-and-below {
      grid-template-rows: 4fr 0.8fr;
      grid-template-columns: none;
      grid-gap: 10px;
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

  &__grid-selected-seg {
    display: grid;
    grid-template-rows: 1fr 3fr;
    grid-gap: 10px;
    margin: 10px;
  }

  &__grid-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
  }

  &__right {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__expanded {
    width: 100%;
    margin: 0 auto;
  }

  &__expanded-gray {
    width: 100%;
    margin: 0 auto;
  }

  &__padding {
    padding: 10px 0px;
  }

  .MuiTableContainer-root {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .MuiTable-root {
    background: rgba(0, 0, 0, 0.02);
    box-shadow: none !important;
  }

  .MuiTableCell-root {
    @include tablet-landscape-and-below {
      font-size: 0.85em;
      padding: 5px;
    }
  }
  .MuiTableCell-sizeSmall:last-child {
    @include tablet-landscape-and-below {
      padding-right: 5px;
    }
  }
}

.border {
  background-color: hsla(0, 0%, 0%, 0.3);
}

.grid__12 {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, auto));
  grid-gap: 1px;
  & > div {
    background-color: white;
    padding: 10px;
  }
}
