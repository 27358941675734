$color-green: #44b86a;
$color-light-green: #42cf99;

$color-blue: #388fd1;
$color-light-blue: #33c9e8;

$gradient-blue-green: linear-gradient(315deg, #037ade 0%, #74d680 74%);

%card-attr {
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid #e7eaec;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
