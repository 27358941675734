.holver-container {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.holver-title {
  font-weight: bold;
}

.holver-color {
  color: #808080;
}
