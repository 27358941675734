.segments-display {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 20px 0px;
}

.map-cointainer {
  margin: 45px 5px 20px -5px;
}

.span-color {
  color: darkgray;
  font-size: 14px;
}

.img-contrainer {
  height: 300px;
  margin-top: 10px;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.crop-alerts {
  width: 920px;
  margin-bottom: 5px;
}

.excel-button {
  margin: 5px 12px !important;
}
