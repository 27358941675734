@import "../../style/Mixins.scss";

.container {
  display: flex;
  flex-direction: row;
  min-width: 160px;

  & > *:first-child > * {
    margin-right: 15px;
  }
}

.mobile-container {
  display: flex;
  flex-direction: row;

  & > *:first-child > * {
    margin-right: 15px;
  }
}
