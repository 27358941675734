@import "../../style//Mixins.scss";

$close-button-size: 34px;

.general-modal {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 10;
    opacity: 0.4;
    cursor: pointer;
    visibility: visible;

    transition: all 0.5s;

    &--hidden {
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s;
    }
  }

  &__card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;

    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    z-index: 8;

    transition: all 0.5s;

    &--hidden {
      display: none;
    }

    @include tablet-landscape-and-below {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  &__close-button {
    position: absolute;
    top: -18px;
    right: -18px;
    width: $close-button-size;
    height: $close-button-size;
    background-color: #222;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.6rem;
    font-weight: bold;

    user-select: none;
    cursor: pointer;

    transition: all 0.5s;

    @include tablet-landscape-and-below {
      position: fixed;
      top: 0px;
      right: 0px;
      background-color: white;
      color: black;
      font-size: 3rem;
    }
  }

  &__notch-none {
    margin-top: 30px;
  }

  &__notch-full {
    margin-top: 110px;
  }

  &__notch-minimal {
    margin-top: 80px;
  }
}
