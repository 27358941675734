.historic-table {
  height: 200px;
  animation: fadein 2s !important;
}

.MuiTableRow-root {
  cursor: pointer;
  animation: fadein 1s !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

@keyframes fadein {
  from {
    opacity: 0;
    background-color: rgba(67, 160, 71, 0.62);
  }
  to {
    opacity: 1;
    background-color: transparent;
  }
}
