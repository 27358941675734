.remove {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 150px;
  position: absolute;

  &__title {
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #797979;
    margin: 20px 20px 20px 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  &__button {
    //width: 100%;
    margin: 10px;
  }
}
