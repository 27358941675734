.greyGridTable {
  border: 2px solid #ffffff;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.greyGridTable td,
table.greyGridTable th {
  // border: 1px solid #FFFFFF;
  padding: 2px 4px;
}
.greyGridTable tbody td {
  font-size: 12px;
  padding: 2px 4px;
}
// .greyGridTable td:nth-child(even) {
//   background: #EBEBEB;
// }
table.greyGridTable tr:nth-child(even) {
  background: #44b86a;
}
.greyGridTable thead {
  background: #ffffff;
  border-bottom: 1px solid #333333;
}
.greyGridTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  border-left: 2px solid #333333;
}
.greyGridTable thead th:first-child {
  border-left: none;
}

.greyGridTable tfoot td {
  font-size: 14px;
}
