@import "../../../../style/Mixins.scss";

.dashboard-container {
  transition: all 0.5s;
  // padding: 50px 55px 10px 55px;
  width: 100%;
  height: 100%;

  // overflow-x: hidden;

  @include tablet-landscape-and-below {
    padding: 70px 10px 10px 10px;
    overflow: auto;
  }

  &__notch-full {
    padding: 110px 10px 10px 10px;
  }

  &__notch-minimal {
    padding: 80px 10px 10px 10px;
  }
}

.progress {
  color: #44b86a;
  margin-left: 20vw;
  margin-top: 20vh;
}
