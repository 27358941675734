@import "../../../../style/Vars.scss";
@import "../../../../style/Mixins.scss";

$animation-time: 0.3s;

.drawer {
  .MuiInputBase-root {
    color: white !important;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    transition: 3s;

    &--inactive {
      display: none;
    }
  }

  &__bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    height: 100vh;
    background-color: #2f4050;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    z-index: 996;
    // parte responsable for the animation transition
    transition: width $animation-time;
    user-select: none;
    overflow: hidden;
    overflow-y: hidden;

    @include tablet-landscape-and-below {
      padding-left: 46px;
      width: 266px;
      top: 55px;
      left: -46px;
    }

    & > * {
      user-select: none;
    }

    &--inactive {
      width: 40px;
      transition: width $animation-time;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }

  &__items {
    color: #9ea6b9;
    color: hsla(0, 0%, 100%, 0.4);

    &__notch-full {
      padding-top: 40px !important;
    }

    &__notch-minimal {
      padding-top: 10px !important;
    }
  }

  &__submenu {
    background-color: #293745;
    overflow: hidden;
    max-height: 0px;
    transition: max-height $animation-time;
    position: relative;

    &--visible {
      max-height: fit-content;
      &--scroll {
        max-height: 45vh;
        overflow-y: auto;
      }
    }

    &__item {
      cursor: pointer;
      height: 36px;
      padding-left: 8px;

      overflow: hidden;

      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 0.8rem;
      white-space: nowrap;

      transition: color $animation-time;

      &:hover {
        color: white;
      }

      svg {
        height: 12px;
      }
    }

    &__label {
      transition: margin-left $animation-time;
      &--hidden {
        margin-left: 20px;
      }
      &--selected {
        color: white;
        font-weight: bold;
      }
    }
  }

  @mixin item($padding, $fontsize) {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-size: $fontsize;
    padding: $padding;
    position: relative;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.08);
    font-weight: bold;
    transition: color $animation-time, background-color $animation-time;

    &:hover {
      color: white;

      svg {
        color: white;
      }
    }

    &--selected {
      border-left: 4px solid $color-green;
      background-color: #293745;
      padding-left: 4px;

      & > * {
        color: white;
      }
    }

    &--submenu-active {
      background-color: #293745;
      border-bottom: none;
    }

    svg {
      height: 20px;
      object-fit: contain;
    }

    &__profile {
      padding: 18px 8px;
    }

    &__label {
      margin-left: 8px;
      //font-size: $fontsize;
      white-space: nowrap;
      transition: margin-left $animation-time;
      font-size: 14px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &--hidden {
        margin-left: 14px;
      }
    }

    &__arrow {
      position: absolute;
      right: 15px;

      &--closed {
        display: none;
      }
    }
  }

  &__item {
    @include item(12px 8px, $fontsize: 0.8rem);
  }

  &__itemp {
    @include item(20px 8px, $fontsize: 1rem);
  }
}

.dev-container {
  padding: 10px;
  height: 61px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.dev-icon {
  margin-right: 13px;
}

button.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected {
  background-color: #44b86a;
  color: #fff;
}
