@import "../../../../style/Vars.scss";
@import "../../../../style/Mixins.scss";

.root {
  box-sizing: border-box;
  width: 100%;

  height: 100%;
  padding: 0 15px 15px 15px;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .input-div {
    height: 100%;

    display: flex;

    flex-direction: column;

    margin-left: -15px; //Trick to do reverse padding so the borders extend to the edges
    margin-right: -15px;
    width: calc(100% + 30px);
  }

  @include tablet-landscape-and-below {
    width: 100vw;
    padding-top: 20px !important;
  }
}

.loading-div {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  top: 0px;
  left: 0px;

  background-color: #ffffffaa;

  z-index: 999;
}

.create-equipment-button {
  min-width: 300px;
  width: 100%;

  padding-top: 25px;
  padding-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > div.button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.4px;

    background-color: #0057ff;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 100%;

    & > svg {
      margin-right: 0px;
    }

    &:active {
      opacity: 0.6;
    }
  }
}

span.title {
  font-weight: 500;
  font-size: 18px;
  /* identical to box height */

  width: 100%;

  color: #797979;
}

.qr-reader-root {
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  background-color: black;

  z-index: 9999;

  & > .close-button {
    position: fixed;
    top: 30px;
    right: 20px;

    color: white;
    font-weight: bold;
    z-index: 10000;
  }
}

.device-row {
  position: relative;
  box-sizing: border-box;
  display: flex;

  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  & > .camera-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dbdbdb;

    min-width: 40px;
    height: 40px;

    border-radius: 5px;
    margin-left: 15px;
  }

  & > .input {
    width: 100%;
    height: 100%;
  }
}

.item-card {
  display: grid;

  grid-template-areas:
    "title item"
    "code  item";

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 40px;

  width: 100%;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 10px;

  & > .title-area {
    grid-area: title;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #6c6c6c;
  }

  & > .code-area {
    grid-area: code;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #6c6c6c;

    opacity: 0.4;
  }

  & > .item-area {
    grid-area: item;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.inputs {
  margin: 0 1rem;
}

.marginTop {
  margin-top: 0.6rem;
}

.marginTop2 {
  margin-top: 1.3rem;
}
