.absolute-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.warning {
  width: auto;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 10px 10px 36px -15px rgba(0, 0, 0, 0.75);
  position: absolute;
  outline: none;

  &__title {
    height: 100%;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #797979;
    white-space: pre-line;
    margin: 20px 20px 20px 20px;
  }

  &__button {
    margin-bottom: 15px;
  }
}
