@import "../../../style/Vars.scss";

.forget-password {
  width: 100%;
  background: $gradient-blue-green;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    @extend %card-attr;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__cardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0px;
    font-size: 18px;
    line-height: 24px;
    &__title {
      margin: 0 30px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__reCaptcha {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__card-information {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;

    &__firstSelect {
      margin: 20px 0px 20px 30px;
    }
    &__select {
      margin: 0px 0px 20px 30px;
    }
  }

  &__passwordRecovey {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__margin {
    height: 20vh;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.135px;
    margin-top: 30px;
    padding: 0px 30px 0px 30px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #e0e0e0;
  }
}
