@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 129, 250, 0.5);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(0, 129, 250, 0);
  }
}

@mixin layout($padding) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: $padding;
}

.edit-irpd {
  @include layout(10px 10px 10px 55px);
}

.edit-iprd-mobile {
  @include layout(0px 0px 0px 0px);
}

.edit-irpd-form {
  height: 100%;
  &__information {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  &__last-settings {
    font-size: 0.8em;
    opacity: 0.5;
  }

  &__done-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation: shadow-pulse-big 1s 1;
  }
}
