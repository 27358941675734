@import "../../../../../../../../style/Mixins.scss";

$break-0: 0;
$break-1: 20em;
/* 320px */
$break-2: 30em;
/* 480px */
$break-3: 37.5em;
/* 600px */
$break-4: 48em;
/* 768px */
$break-5: 60em;
/* 960px */
$break-6: 68.75em;
/* 1100px */

$browser-default-font-size: 16px;
$layout-size: 16px;

$font-stack-montserrat: "Montserrat", sans-serif;
$font-stack-roboto-slab: "Roboto Slab", serif;

/* Colors 
// ========================================================================== */
$color-text: #5f6364;
$color-white: #fff;
$color-blue: #1b86a3;
$color-blue-highlight: #1a7d90;
$color-red: #b31218;
$color-grey: #5f6364;
$color-light-grey: #f2f3f4;
$color-darker-grey: #cacccd;
$color-text: #5f6364;

/* Convert px to ems
// ========================================================================== */
@function px-to-ems($pixels, $context: $browser-default-font-size) {
  @return #{$pixels/$context}em;
}

@function return-ems($pixels, $context) {
  @return #{$pixels/$context}em;
}

@function layout-ems($pixels, $context: $layout-size) {
  @return #{$pixels/$context}em;
}

@mixin center {
  margin-left: auto;
  margin-right: auto;
}

.hide-second-item {
  .MuiFormControl-root {
    visibility: hidden;
  }
}

.schedule-irrigation-v5 {
  padding-bottom: 15px;
  max-width: 1500px;
  min-width: 1000px;
  width: 100%;

  &__form {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 10px;
    row-gap: 0px;

    @include tablet-landscape-and-below {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;
    }
  }
  &__fields {
    margin-top: 15px;
    display: grid;
    grid-template-rows: 0.4fr 0.85fr;
    height: 50%;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;

    @include tablet-landscape-and-below {
      grid-column: 1/3;
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 65%;
    margin-left: 8%;
  }

  &__value {
    padding-top: 3px;
    padding-bottom: 10px;
    font-size: 90%;
    margin-left: 8%;
  }

  &__row-container {
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }

  &__row {
    display: flex;
    flex-direction: row;

    & > *:first-child > * {
      margin-right: 15px;
    }
  }

  &__right-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 10px;
  }

  @include tablet-landscape-and-below {
    padding: 0px 13px 13px 13px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__clear-table {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #0089d6;
  }

  .MuiIconButton-colorPrimary {
    color: rgba(0, 0, 0, 0.5);
  }
}

.scrollable {
  max-height: 45vh;
  overflow-y: auto;

  @include tablet-landscape-and-below {
    height: 45vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.progress-bar {
  display: flex;
  flex-direction: center;
  justify-content: center;
}
