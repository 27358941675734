@import "../../../../style/Vars.scss";
@import "../../../../style/Mixins.scss";

@mixin super-desktop-and-below {
  @media only screen and (max-width: 1900px) {
    @content;
  }
}

@mixin super-desktop-and-up {
  @media only screen and (min-width: 1900px) {
    @content;
  }
}

@mixin selected-farm($padding) {
  padding: $padding;

  h1 {
    color: #2f4050;
    font-weight: 400;
    margin: 12px 0;
  }

  svg {
    cursor: pointer;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 2fr 10fr;
    min-height: 0px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  &__devices {
    max-height: 100vh;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__map-container {
    width: 100%;
    height: 100%;
  }

  &__map {
    width: 100%;
    height: calc(100vh - 150px);
    position: relative !important;

    @include tablet-landscape-and-below {
      height: 50vh;
    }
  }
}

.selected-farm-desktop {
  @include selected-farm(
    50px 10px 0px 55px
  ); // padding com a barra lateral direita(50px 55px 15px 55px);
}

.selected-farm-container {
  position: fixed;
  top: 10px;
  left: 60px;
  z-index: 3;
}

.selected-farm-map-container {
  padding: 0 0 0 40px;
  width: 100vw;
  height: 100vh;
}

.selected-farm-mobile {
  @include selected-farm(0px 0px 0px 0px);
}
