@import "../../../../../../../../../../style/Mixins.scss";

.container {
  display: flex;
  flex-direction: row;

  & > *:first-child > * {
    margin-right: 15px;
  }

  @include tablet-landscape-and-below {
    flex-direction: column;
    & > *:first-child > * {
      margin-right: 0px;
    }
  }
}
