@import "../../../../style/Mixins.scss";

$large: 1300px;

@mixin selected-pivot-bauer($padding) {
  padding: $padding;
  position: relative;
  z-index: 0;

  &__grid {
    position: relative;
    display: grid;
    // grid-template-columns: 11fr;
    grid-template-columns: 8fr 4fr;

    grid-gap: 15px;

    @media (max-width: 1556px) {
      // grid-template-columns: 11fr;
      grid-template-columns: 8fr 4fr;
    }

    @media (max-width: $large) {
      grid-template-columns: 100%;
      grid-gap: 20px;
    }

    // @include tablet-landscape-and-below {
    //   grid-template-columns: 1fr;
    //   grid-gap: 20px;
    // }
  }

  &__column {
    position: relative;
    max-width: 100%;
    min-width: 400px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    row-gap: 15px;

    @include tablet-landscape-and-below {
      min-width: auto;
    }
  }

  &__column_single_item {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    row-gap: 15px;
    height: calc(100vh - 150px);
    grid-template-rows: min-content;
  }

  &__two-columns {
    @media (min-width: $large + 1) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row: 2;
    }
  }

  &__two-rows {
    @media (min-width: $large + 1) {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column: 2;
    }
  }
}

.selected-pivot-bauer {
  @include selected-pivot-bauer(
    10px 10px 20px 55px
  ); //@include selected-pivot(50px 55px 10px 55px);
}

.selected-pivot-bauer-mobile {
  @include selected-pivot-bauer(0px 0px 30px 0px);
}

.flex-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-right-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: center;
}

.margin-right-15 {
  // margin-right: 15px;
}

.desktop-quickselect {
  border: none;
  background: transparent;
  color: black;
  font-weight: bold;
  font-size: 30px;
  width: fit-content;
}

.desktop-quickselect-option {
  font-size: 20px;
  color: gray;
}

.mobile-quickselect {
  border: none;
  background: transparent;
  color: white;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
}

.mobile-quickselect-option {
  font-size: 15px;
  color: gray;
}

.bauer-reports-construction-card {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: none;
}

.bauer-reports-construction-text {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  padding: 20%;
}
