@import "../../../../../../../../../style/Mixins.scss";

.users {
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: black;
    align-items: center;
  }

  &__row {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 15px;

    &__status-drop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      // max-width: 100px;
    }

    &__code {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #797979;
    }

    &__status {
      font-weight: 600;
      font-size: 0.7rem;
      margin: 4px 0px 4px 8px;
      padding: 4px 8px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      background-color: gold;
      height: 30px;
    }
  }

  &__first-row {
    padding: 16.5px 10px 16.5px 10px;
  }

  &__titleRow {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    padding: 10px;
    font: bold;
  }
}

.no-items {
  margin: 20px 0 0px 0;
  opacity: 0.6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet-landscape-and-below {
    margin-bottom: 15px;
  }
}

.button-farm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;

  // min-width: 300px;
  // width: 100%;

  // padding: 25px;

  // display: flex;
  // justify-content: center;
  // align-items: center;

  // & > div.button {
  //   font-family: Roboto;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 14px;
  //   line-height: 16px;
  //   color: #fff;

  //   text-align: center;
  //   letter-spacing: 0.4px;

  //   background-color: #0066ff;
  //   border-radius: 5px;

  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   height: 50px;
  //   width: 100%;

  //   & > svg {
  //     margin-right: 0px;
  //   }

  //   &:active {
  //     opacity: 0.6;
  //   }
  // }
}
