@import "../../../../../../../../style/Mixins.scss";

.expanded-content {
  &__general {
    display: flex;
    flex-direction: column;
    background-color: lightgray;

    @include tablet-landscape-and-below {
      font-size: 0.7rem;
    }
  }

  &__no-button-title {
    margin-right: 15%;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
  }

  &__title- {
    margin-right: 15%;
    margin-top: 2px;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
  }

  &__title {
    flex: 5;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;

    @include tablet-landscape-and-below {
      font-size: 0.8rem;
    }
  }

  &__title-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include tablet-landscape-and-below {
      margin-left: 0px;
    }
  }

  &__button {
    height: 100%;
    width: 100%;
  }

  &__background {
    white-space: pre-line !important;
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px;

    @include tablet-landscape-and-below {
      padding: 10px;
    }
  }

  &__group-padding {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;

    @include tablet-landscape-and-below {
      padding: 0px 10px 0px 0px;
      text-align: left;
    }
  }

  &__group {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    cursor: text;
  }

  &__group-no-padding {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    cursor: text;
  }

  &__group-row {
    padding: 15px;
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    @include tablet-landscape-and-below {
      padding: 0;
    }
  }

  &__column-6-6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      row-gap: 7px;
    }
  }

  &__group-row-end-gun {
    padding: 15px;
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include tablet-landscape-and-below {
      padding: 0;
    }
  }
}

.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.flex-container-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
}
