@import "../../../../../../../../../../style/Mixins.scss";

.permission {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  max-height: 650px;
  min-width: 400px;
  position: relative;

  @include tablet-landscape-and-below {
    min-width: 200px;
    max-width: 100vw;
  }

  &__list-pivot {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 22em;
  }

  &__head {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 140px;
    grid-column-gap: 10px;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;

    & > .pivot {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    & > .tag {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__button {
    // position: absolute;

    border-top: 1px solid #e0e0e0;
    padding: 15px;
    // padding: 300px 0px 0px 0px;
  }

  &__actions {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__user-info {
    margin: 0 0 3px 8px !important;

    &__pending {
      margin: 0 0 3px 8px !important;
      padding-top: 10px !important;
    }
  }
}
