@import "../../style/Mixins.scss";

.general-form {
  &__container {
    width: 100%;
    max-width: 1100px;
  }

  &__group {
    padding: 30px;

    @include tablet-landscape-and-below {
      padding: 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px dotted grey;
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      font-size: 1rem;
      color: #555;
      font-weight: 600;
      text-transform: uppercase;

      svg {
        margin-right: 12px;
      }
    }
  }

  &__row-flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 30px;
  }

  &__row-flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 30px;
  }

  &__row-center {
    text-align: center;
    padding: 30px;
  }

  &__row-12 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-6-6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-7-5 {
    display: grid;
    grid-template-columns: 1fr 0.45fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-4-4-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-3-3-3-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
      row-gap: 15px;
    }
  }

  &__row-2-3-11 {
    display: grid;
    grid-template-columns: 2fr 3fr 11fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-5-5 {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-5 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-5-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-5-5-2 {
    display: grid;
    grid-template-columns: 6fr 6fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 3fr 3fr 1fr;
      grid-gap: 10px;
    }
  }

  &__row-1-11 {
    display: grid;
    grid-template-columns: 1fr 15fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-10 {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-10-2 {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-2-2-2-2-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-1-1-1-1-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-x-1-1-1-1-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-1-1-1-1-05-05 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-1-1-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-2-2-1-1-1-1 {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr;
      row-gap: 5px;
      column-gap: 10px;
    }
  }

  &__row-center-2-2-1-1-1-1 {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    align-items: center;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr;
      row-gap: 5px;
      column-gap: 10px;
    }
  }

  &__row-center {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    align-items: center;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      row-gap: 5px;
      column-gap: 10px;
    }
  }

  // TODO: Tablet styles

  &__row-5items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

  &__row-6items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-7items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }

  &__row-7items-segment {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

  &__col-2 {
    display: grid;

    @include tablet-landscape-and-below {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }

  &__multiple-input-container {
    display: flex;
    flex-direction: row;

    & > div {
      margin: 16px;
    }
  }
}

.white-body {
  background-color: white;
}

.modal-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999;
  font-size: 1, 2rem;
  font-weight: 800;
  margin: 10px 0px;
}
